<template>
  <!-- <body class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper">
      
      <Preloader></Preloader>
      <Navbar></Navbar>
      <Sidebar></Sidebar>

      <div class="content-wrapper">
        
        <ContentHeader></ContentHeader>

        <section class="content">
          <div class="container-fluid">
            <ExampleForm></ExampleForm>
          </div>
        </section>
        
      </div>
      
      <Footer></Footer>
      <ControlSidebar></ControlSidebar>
      
    </div>
  </body> -->
  <router-view/>
  <vue-progress-bar></vue-progress-bar>
</template>

<script>
// import $ from 'jquery'
// import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
// import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"

// import Preloader from "./dashboard/Preloader.vue"
// import ContentHeader from "./dashboard/ContentHeader.vue"
// import ExampleForm from "./components/ExampleForm.vue"
// import Navbar from "./dashboard/Navbar.vue";
// import Sidebar from "./dashboard/Sidebar.vue";
// import Footer from "./dashboard/Footer.vue";
// import ControlSidebar from "./dashboard/ControlSidebar.vue"

let brand = " | " + process.env.VUE_APP_TITLE
export default {
  name: "App",
  components: {
    // Preloader,
    // ContentHeader,
    // ExampleForm,
    // Navbar,
    // Sidebar,
    // Footer,
    // ControlSidebar
  },
  watch: {
    $route(to){
      document.title = to.meta.title + brand,
      document.description = to.meta.description
    }
  },
  methods: {
    // initializeSelect2() {
    //   //Initialize Select2 Elements
    //   $(".select2").select2();

    //   //Initialize Select2 Elements
    //   $(".select2bs4").select2({
    //     theme: "bootstrap4",
    //   });
    // },
  },
  mounted() {
    // this.initializeSelect2()
  }
};
</script>

<style>
  @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
  @font-face {
    font-family: "AmiriFont";
    src: url('assets/fonts/amiri/Amiri-Regular.ttf');
  }
  .font-amiri{
    font-family: 'AmiriFont', serif;
    color: black;
    font-weight: 300;
    font-size: 15px;
  }
  .font-amiri-header{
    font-family: 'AmiriFont', serif;
    color: black;
    font-weight: bolder;
    font-size: 17px;
  }
  .font-amiri-muted{
    font-family: 'AmiriFont', serif;
    color: gray;
    font-weight: 300;
    font-size: 13px;
  }
</style>
