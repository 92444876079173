<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <a href="#">หน้าแรก</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">
                            <router-link to="/samanAttendance">
                                <a href="#">วิชา</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">
                            การมาเรียน
                        </li>
                        <!-- <li class="breadcrumb-item active">รายงานการมาเรียน</li> -->
                    </ol>
                    <router-link to="/samanAttendance">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <table class="table table-bordered bg-white mt-3">
                <thead>
                    <tr class="bg-secondary">
                        <td class="text-white text-center" colspan="3">
                            <div>
                                {{subject}}
                            </div>
                            <div>
                                อ.{{teacher}}
                            </div>
                        </td>
                    </tr>
                    <tr class="bg-secondary">
                        <td align="center" class="font-amiri-header text-white">วันที่</td>
                        <td align="center" class="font-amiri-header text-white">คาบ</td>
                        <td align="center" class="font-amiri-header text-white">สถานะ</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in attendanceDetails" :key="index">
                        <td align="center">
                            {{item.date_check}}
                        </td>
                        <td align="center">
                            {{item.hisah}}
                        </td>
                        <td align="center">
                            <span v-if="item.status == 1"> มา </span>
                            <span v-else-if="item.status == 2">  สาย </span>
                            <span v-else-if="item.status == 3" class="text-danger">  ขาด </span>
                            <span v-else-if="item.status == 4" >  ลากิจ </span>
                            <span v-else> ลาป่าย  </span>
                        </td>
                    </tr>
                </tbody>
            </table>   
        </div>
        <br>
    </section>
</template>
<script>
    import http from '@/services/auth'
    export default{
        mounted(){
            this.retreiveDetail()
        },
        data(){
            return{
                attendanceDetails: [],
                subject: '',
                teacher: '',
            }
        },
        methods:{
            retreiveDetail(){
                this.$Progress.start()
                http.get(`saman/attendanceDetail/${this.$route.params.rom_id_code}/${this.$route.params.suj_id_code}`)
                    .then(response => {
                        this.attendanceDetails = response.data.report
                        this.subject = response.data.subject
                        this.teacher = response.data.teacher
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        }
    }
</script>