<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <a href="#">หน้าแรก</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">วิชา</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <h4>รายงานการมาเรียน</h4>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <select class="form-control" v-model="rom_id_code">
                    <option v-for="(item, index) in classrooms" :value="item.rom_id_code" :key="index">ม.{{ item.grade }}/{{ item.room }} [{{ item.semester }}/{{ item.year }}]</option>
                </select>
            </div>
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item bg-secondary mt-2" aria-disabled="true">
                        ม.{{ selectedRoom.grade }}/{{ selectedRoom.room }} [{{ selectedRoom.semester }}/{{ selectedRoom.year }}]
                    </li>
                    <li class="list-group-item" v-for="(item, index) in attendance" :key="index">
                        <div style="display: flex; justify-content: space-between;">
                            <span>{{ item.suj_code }} {{ item.suj_name_th }}</span>
                            <span>  
                                <router-link :to="{name: 'SamanAttendanceDetail', params:{rom_id_code: rom_id_code, suj_id_code:item.suj_id_code}}">
                                    <button class="btn btn-secondary text-white btn-sm">
                                        <i class="fa fa-arrow-circle-right"></i>
                                    </button>
                                </router-link>                            
                            </span>
                        </div>
                        <div class="text-muted" style="line-height: 10px">
                            อ.{{ item.stf_name }} {{ item.stf_lastname }}
                        </div>
                        <div class="mt-3 text-center text-sm">
                            <span class="text-bold">มา : </span><span class="text-success">{{ item.attendance.come }}</span> | 
                            <span class="text-bold">สาย : </span><span class="text-warning">{{ item.attendance.late }}</span> | 
                            <span class="text-bold">ขาด : </span><span class="text-red">{{ item.attendance.absent }}</span> | 
                            <span class="text-bold">ลากิจ : </span><span class="text-secondary">{{ item.attendance.leave }}</span> | 
                            <span class="text-bold">ลาป่วย : </span><span class="text-primary">{{ item.attendance.sickLeave }}</span>
                        </div>
                        <div class="text-bold mt-1 text-center text-sm">
                            [ ทั้งหมด : {{ item.attendance.total }} คาบ]
                        </div>
                    </li>
                </ul>  
            </div>
        </div>
        <br>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            this.retreiveClassroom()
        },
        data(){
            return{
                classrooms: [],
                attendance: [],
                rom_id: '',
                rom_id_code: '', 
                selectedRoom: {
                    rom_id: '',
                    rom_id_code: '',
                    grade: '',
                    room: '',
                    semester: '',
                    year: '',
                },
            }
        },
        watch:{
            rom_id_code: function(){
                this.retreiveAttendance(this.rom_id_code)
            }    
        },
        methods:{
            retreiveAttendance(rom_id_code){
                this.$Progress.start()
                http.get(`saman/attendance/${rom_id_code}`)
                    .then(response => {
                        this.attendance = response.data.attendance
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveClassroom(){
                http.get('saman/classroom')
                    .then(response => {
                        let classrooms = response.data.classrooms
                        this.classrooms = classrooms
                        // let selectedRoom = classrooms.filter(item => item.year == lastYearStudyClass && item.semester == currentPeriod)[0]
                        let selectedRoom = classrooms[classrooms.length - 1]
                        this.selectedRoom = selectedRoom
                        this.rom_id = selectedRoom.rom_id
                        this.rom_id_code = selectedRoom.rom_id_code
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        }    
    }
</script>

<style lang="scss" scoped>

</style>