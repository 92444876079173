<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <a href="#">Home</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">laporan kehadiran</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <router-link to="/dashboard">
                        <i class="fa fa-home"></i>
                    </router-link>
                </div>
                <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item active">
                            </li>
                        </ol>
                </div>
            </div>
        </div>
    </div> -->
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <h3 class="text-right font-amiri-header">لافوران كحاضران</h3>
            </div>
            <div class="d-flex flex-row-reverse">
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                    <select class="form-control" v-model="agr_id">
                        <option v-for="(item, index) in classroom" :value="item.agr_id" :key="index">
                            {{ item.agr_grade }}/{{ item.agrroom_name }} [{{ item.agr_semester }}/{{ item.agr_academic_year }}]
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 desktop-show mt-2">
                <div class="card mb-3 text-right bg-info font-amiri-header" dir="rtl">
                    <div class="card-body">
                    <!-- <blockquote class="card-blockquote"> -->
                        <p class="meaning-short-text">1. ح = حاضر </p>
                        <p class="meaning-short-text">2. ل = ليوت </p>
                        <p class="meaning-short-text">3. غ = غائب </p>
                        <p class="meaning-short-text">4. چ = چوتي </p>
                        <p class="meaning-short-text">5. ع = عذر </p>
                        <p class="meaning-short-text">6. س = سموا </p>
                    <!-- </blockquote> -->
                    </div>
                </div>
                <table class="table table-bordered bg-white mt-3" dir="rtl">
                    <thead>
                        <tr class="bg-secondary">
                            <td align="center" class="text-white">#</td>
                            <td align="center" class="font-amiri text-white">مادة</td>
                            <td align="center" class="font-amiri text-white">استاذ\ة</td>
                            <td align="center" class="text-white">ح</td>
                            <td align="center" class="text-white">ل</td>
                            <td align="center" class="text-white">غ</td>
                            <td align="center" class="text-white">چ</td>
                            <td align="center" class="text-white">ع</td>
                            <td align="center" class="text-white">س</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(subject, index) in report" :key="index">
                            <td align="center">
                                <router-link :to="{name: 'AgamaAttendanceDetail', params:{suj_agm_id: subject.suj_agm_id, agr_id: agr_id}}">
                                    <button class="btn btn-primary"><i class="fa fa-arrow-circle-right"></i>
                                        {{subject.sujcode_agm}}
                                    </button> 
                                </router-link>
                            </td>
                            <td align="right" class="font-amiri">{{subject.sujname_agm_ar}}</td>
                            <td align="right" class="font-amiri">{{subject.stf_name_ar}} &nbsp;&nbsp;&nbsp; {{subject.stf_lastname_ar}}</td>
                            <td align="center">{{subject.attendance_report.H}}</td>
                            <td align="center">{{subject.attendance_report.L}}</td>
                            <td align="center">{{subject.attendance_report.G}}</td>
                            <td align="center">{{subject.attendance_report.C}}</td>
                            <td align="center">{{subject.attendance_report.U}}</td>
                            <td align="center">{{subject.attendance_report.total}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 phone-show mt-2">
                <div class="card mb-3 bg-info text-right font-amiri-header" dir="rtl">
                    <div class="card-body">
                        <p class="meaning-short-text">1. ح = حاضر </p>
                        <p class="meaning-short-text">2. ل = ليوت </p>
                        <p class="meaning-short-text">3. غ = غائب </p>
                        <p class="meaning-short-text">4. چ = چوتي </p>
                        <p class="meaning-short-text">5. ع = عذر </p>
                        <p class="meaning-short-text">6. س = سموا </p>
                    </div>
                </div>
                <table class="table table-bordered bg-white mt-3" dir="rtl">
                    <thead>
                        <tr class="bg-secondary">
                            <td align="center" class="font-amiri text-white">مادة</td>
                            <td align="center" class="text-white">ح</td>
                            <td align="center" class="text-white">ل</td>
                            <td align="center" class="text-white">غ</td>
                            <td align="center" class="text-white">چ</td>
                            <td align="center" class="text-white">ع</td>
                            <td align="center" class="text-white">س</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(subject, index) in report" :key="index">
                            <td align="right" class="font-amiri">
                                <router-link :to="{name: 'AgamaAttendanceDetail', params:{suj_agm_id: subject.suj_agm_id, agr_id: agr_id}}">
                                    <button class="btn btn-primary text-white"><i class="fa fa-arrow-circle-right"></i></button>
                                </router-link>
                                {{subject.sujname_agm_ar}}
                                
                            </td>
                            <td align="center">{{subject.attendance_report.H}}</td>
                            <td align="center">{{subject.attendance_report.L}}</td>
                            <td align="center">{{subject.attendance_report.G}}</td>
                            <td align="center">{{subject.attendance_report.C}}</td>
                            <td align="center">{{subject.attendance_report.U}}</td>
                            <td align="center">{{subject.attendance_report.total}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            // this.retreiveAttandanceReport()
            this.retreiveClassroom()
        },
        data(){
            return{
                report: [],
                classroom: [],
                agr_id: '',
                selectedRoom: {
                    agr_room: '',
                    agrroom_name: '',
                    agr_semester: '',
                    agr_acamidemic_year: '',
                },
            }
        },
        watch: {
            agr_id: function(){
                this.retreiveAttandanceReport(this.agr_id)
            }
        },
        methods:{
            retreiveAttandanceReport(agr_id){
                this.$Progress.start()
                http.get(`attendanceAgama/report/${agr_id}`)
                    .then(response => {
                        this.report = response.data.result
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveClassroom(){
                http.get(`agama/classroom`)
                    .then(response => {
                        let classroom = response.data.classrooms
                        this.classroom = classroom
                        let selectedRoom = classroom[classroom.length - 1]
                        this.agr_id = selectedRoom.agr_id
                        this.selectedRoom = selectedRoom
                        
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        },
    }
</script>

<style scoped>
    .meaning-short-text{
        line-height: 10px;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 575px) {
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
        }
    }
    /* Small devices (landscape phones, less than 768px) */
    @media (min-width: 767.98px) { 
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
            
        }
    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) { 
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
        }
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) { 
        .desktop-show{
            display: block;
        }
        .phone-show{
            display: none;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) { 
        .desktop-show{
            display: block;
        }
        .phone-show{
            display: none;
        }
    }
</style>