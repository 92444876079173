<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <a href="#">หน้าแรก</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">วิชา</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                    <h4 class="text-left">ผลการเรียน</h4>
                </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                <select class="form-control" v-model="rom_id_code">
                    <option v-for="(item, index) in classrooms" :value="item.rom_id_code" :key="index">ม.{{ item.grade }}/{{ item.room }}  [{{ item.semester }}/{{ item.year }}]</option>
                </select>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-lg btn-block" :class="{'btn-success': !isShowAll, 'btn-default': isShowAll}" @click="showAll(false)">เทอมที่เลือก</button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-lg btn-block" :class="{'btn-success': isShowAll, 'btn-default': !isShowAll}" @click="showAll(true)">แสดงทุกเทอม</button>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <span v-for="(item, index) in semesterSubjects" :key="index">
                    <ul class="list-group mt-2" v-if="item.show">
                        <li class="list-group-item bg-secondary">
                            ม.{{ item.room.grade }}/{{ item.room.room }} เทอม {{ item.room.semester }} ปี {{ item.room.year }}
                        </li>
                        <li class="list-group-item" v-for="(item, index) in item.subjects" :key="index">
                            <div style="display: flex; justify-content: space-between;">
                                <span>{{ item.suj_code }} {{ item.suj_name_th }}</span>
                                <span>  
                                    {{ item.scr_score }}
                                </span>
                            </div>
                            <div class="text-muted">
                                อ.{{ item.teacher }} ( หน่วยกิต {{ item.suj_credit }} )
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div style="display: flex; justify-content: space-between;">
                                <span>GPA</span>
                                <span> {{ item.gpa.gpa }}</span>
                            </div>
                        </li>
                    </ul>
                </span>
                <br>
            </div>
        </div>
    </section>
</template>
<script>
    import http from '@/services/auth'
    export default{
        mounted(){
            this.retreiveClassroom()
        },
        data(){
            return{
                classrooms: [],
                semesterSubjects: [],
                rom_id: '',
                rom_id_code: '',
                selectedRoom: {
                    grade: '',
                    room: '',
                    semester: '',
                    year: '',   
                },
                // currentPeriod: '',
                // lastYearStudyClass: '',
                // firstGrade: '',
                // secondGrade: '',
                isShowAll: false,
            }
        },
        watch:{
            rom_id_code: function(){
                this.retreveSamanResult(this.rom_id_code)
            },
        },
        methods:{
            showAll(isShowAll){
                this.isShowAll = isShowAll
                if(isShowAll == true){
                    this.semesterSubjects = this.semesterSubjects.map(item => {
                        item.show = true
                        return item
                    })
                }else{
                    this.semesterSubjects = this.semesterSubjects.map(item => {
                        if(item.room.rom_id == this.selectedRoom.rom_id){
                            item.show = true
                        }else{
                            item.show = false
                        }
                        return item
                    })
                }
            },
            retreiveClassroom(){
                http.get('saman/classroom')
                .then(response => {
                    let classrooms = response.data.classrooms
                    let selectedRoom = classrooms[classrooms.length - 1]
                    this.selectedRoom = selectedRoom
                    this.rom_id_code = selectedRoom.rom_id_code
                    this.classrooms = classrooms
                })
                .catch(error => {
                    console.log(error)
                })
            },
            retreveSamanResult(rom_id_code){
                this.semesterSubjects = []
                this.$Progress.start()
                http.get('saman/result/'+rom_id_code)
                    .then(response => {
                        let semesterSubjects = response.data.result
                        let selectedRoom = this.classrooms.filter(item => item.rom_id_code == rom_id_code)[0]
                        this.selectedRoom = selectedRoom
                        this.rom_id = selectedRoom.rom_id
                        this.rom_id_code = selectedRoom.rom_id_code
                        semesterSubjects = semesterSubjects.map(item => {
                            if(item.room.rom_id == selectedRoom.rom_id){
                                item.show = true
                            }else{
                                item.show = false
                            }
                            return item
                        })
                        this.semesterSubjects = semesterSubjects
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        console.log(error)
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        }
    }
</script>