<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <a href="#">หน้าแรก</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">วิชา</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                    <h4 class="text-left">คะแนนเก็บ</h4>
                </div>
            <div class="col-md-6">
                <select class="form-control" v-model="rom_id_code">
                    <!-- <option value="">--เลือกการแสดงผล--</option> -->
                    <option v-for="(item, index) in classrooms" :value="item.rom_id_code" :key="index">ม.{{ item.grade }}/{{ item.room }}  [{{ item.semester }}/{{ item.year }}]</option>
                </select>
            </div>
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item bg-secondary mt-2" aria-disabled="true">
                        ม.{{ selectedRoom.grade }}/{{ selectedRoom.room }}  [{{ selectedRoom.semester }}/{{ selectedRoom.year }}]
                    </li>
                    <li class="list-group-item" v-for="(item, index) in semesterSubjects" :key="index">
                        <div style="display: flex; justify-content: space-between;">
                            <span>{{ item.suj_code }} {{ item.suj_name_th }}</span>
                            <span>
                                <router-link :to="{name: 'SamanScoreDetail', params:{rom_id: rom_id_code, suj_id:item.suj_id_code, stf_id: item.stf_id_code}}">                                  
                                    <button class="btn btn-secondary btn-sm">
                                        <i class="fa fa-arrow-circle-right"></i>
                                    </button>
                                </router-link>
                            </span>
                        </div>
                        <div class="text-muted">
                            อ.{{ item.stf_name }} {{ item.stf_lastname }}
                        </div>
                    </li>
                </ul>  
            </div>
        </div>
        <br>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            this.retreiveClassroom()
        },
        data(){
            return{
                classrooms: [],
                semesterSubjects: [],
                rom_id: '',
                rom_id_code: '', 
                selectedRoom: {
                    rom_id: '',
                    rom_id_code: '',
                    grade: '',
                    room: '',
                    semester: '',
                    year: '',
                },
            }
        },
        watch:{
            rom_id_code: function(){
                this.retreiveSemesterSubject(this.rom_id_code)
            }    
        },
        methods:{
            retreiveSemesterSubject(rom_id){
                this.$Progress.start()
                this.semesterSubjects = []
                http.get(`saman/semesterSubject/${rom_id}`)
                    .then(response => {
                        this.semesterSubjects = response.data.subjects
                        this.selectedRoom = this.classrooms.filter(item => item.rom_id_code == this.rom_id_code)[0]
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')   
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveClassroom(){
                http.get('saman/classroom')
                    .then(response => {
                        let classrooms = response.data.classrooms
                        this.classrooms = classrooms
                        let selectedRoom = classrooms[classrooms.length - 1]
                        this.selectedRoom = selectedRoom
                        this.rom_id = selectedRoom.rom_id
                        this.rom_id_code = selectedRoom.rom_id_code
                    })
                    .catch(err => {
                        if(err.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>