<template>
    <body class="hold-transition login-page">
        <router-view></router-view>
    </body>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>