<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard">
                                <a href="#">หน้าแรก</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">
                            <router-link to="/samanScore">
                                วิชา
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">
                                คะแนนเก็บ
                        </li>
                    </ol>
                    <router-link to="/samanScore">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <p style="line-height: 2px"><i class="fa fa-check-circle"></i> คือ ส่งงานหรือได้คะแนนแล้ว</p>
                <p style="line-height: 2px"><i class="fa fa-ban"></i> คือ ยังไม่ส่งงานหรือยังไม่ได้คะแนน</p>
                <ul class="list-group">
                    <li class="list-group-item bg-secondary mt-2" aria-disabled="true">
                        วิชา : {{ subject.suj_code }} {{ subject.suj_name_th }}
                    </li>
                    <li class="list-group-item" v-for="(item, index) in scoreCategories" :key="index">
                        <div style="display: flex; justify-content: space-between;">
                            <span>
                                {{ item.score_name }}
                            </span>
                            <span>
                                <i class="fa fa-ban" v-if="item.point_score == null || item.point_score == ''"></i>
                                <i class="fa fa-check-circle" v-else></i>
                            </span>
                        </div>
                        <div class="text-muted">
                            คะแนนเต็ม {{ item.score_point }} คะแนน
                        </div>
                    </li>
                </ul>  
            </div>
        </div>
        <br>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            this.retreiveScoreDetail()
        },
        data(){
            return{
                rom_id_code: this.$route.params.rom_id,
                suj_id_code: this.$route.params.suj_id,
                stf_id_code: this.$route.params.stf_id,
                scoreCategories: [],
                subject: '',
            }
        },
        methods:{
            retreiveScoreDetail(){
                this.$Progress.start()
                http.get(`saman/scoreDetail/${this.rom_id_code}/${this.suj_id_code}/${this.stf_id_code}`)
                    .then(response => {
                        this.scoreCategories = response.data.scoreCategories
                        this.subject = response.data.subject
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')    
                        }
                        this.$Progress.fail()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>