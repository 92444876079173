import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import BackendLayout from '@/layouts/Backend.vue'
import FrontendLayout from '@/layouts/Frontend.vue'

function authGuard(to, from, next){
  let isAuthenticated = false
  if(store.getters['auth/logedIn']){
    isAuthenticated = true
  }else{
    isAuthenticated = false
  }
  if(isAuthenticated){
    next() //อนุญาติให้เข้าสู่ route และโหลด component ที่ต้องการได้
    // router.push({
    //   name: 'Dashboard'
    // })
  }else{
    next({name: 'Login'})
  }
}

const routes = [
  {
    path: '/',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Login',
        component: require('@/views/frontend/Login.vue').default,
      }
    ],
    meta: {
      title: 'Login',
      description: 'Login',
      requiresVisitor: true
    }
  },
  {
    path: '/login',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Signin',
        component: require('@/views/frontend/Login.vue').default,
      }
    ],
    meta: {
      title: 'Login',
      description: 'Login',
      requiresVisitor: true
    }
  },
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: require('@/views/backend/Dashboard.vue').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'Dashboard',
      description: 'Dashboard',
    }
  },
  {
    path: '/agamaResult',
    component: BackendLayout,
    children:[
      {
        path: '',
        name: 'AgamaResult',
        component: require('@/views/backend/AgamaResult.vue').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'Markah agama',
      description: 'Markah agama',
    }
  },
  {
    path: '/agamaAttendance',
    component: BackendLayout,
    children:[
      {
        path: '',
        name: 'AgamaAttendance',
        component: require('@/views/backend/AgamaAttendance.vue').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'Kehadiran agama',
      description: 'Kehadiran agama'
    }
  },
  {
    path: '/agamaAttendance/:suj_agm_id/:agr_id',
    component: BackendLayout,
    children:[
      {
        path: '',
        name: 'AgamaAttendanceDetail',
        component: require('@/views/backend/AgamaAttendanceDetail.vue').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'Kehadiran agama',
      description: 'Kehadiran agama'
    }
  },
  {
    path: '/samanScore',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'SamanScore',
        component: require('@/views/backend/SamanScore.vue').default,
        beforeEnter: authGuard,    
      }
    ],
    meta:{
      title: 'คะแนนเก็นวิชาสามัญ',
      description: 'คะแนนเก็นวิชาสามัญ'
    }
  },
  {
    path: '/samanScoreDetail/:rom_id/:suj_id/:stf_id',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'SamanScoreDetail',
        component: require('@/views/backend/SamanScoreDetail.vue').default,
        beforeEnter: authGuard,    
      }
    ],
    meta:{
      title: 'คะแนนเก็บวิชาสามัญ',
      description: 'คะแนนเก็บวิชาสามัญ'
    }
  },
  {
    path: '/samanAttendance',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'SamanAttendance',
        component: require('@/views/backend/SamanAttendance.vue').default,
        beforeEnter: authGuard,    
      }
    ],
    meta:{
      title: 'รายงานการมาเรียนสามัญ',
      description: 'รายงานการมาเรียนสามัญ'
    }
  },
  {
    path: '/samanAttendanceDetail/:rom_id_code/:suj_id_code',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'SamanAttendanceDetail',
        component: require('@/views/backend/SamanAttendanceDetail.vue').default,
        beforeEnter: authGuard,    
      }
    ],
    meta:{
      title: 'รายงานการมาเรียนสามัญ',
      description: 'รายงานการมาเรียนสามัญ'
    }
  },
  {
    path: '/samanResult',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'SamanResult',
        component: require('@/views/backend/SamanResult.vue').default,
        beforeEnter: authGuard
      }
    ],
    meta: {
      title: 'ผลการเรียนสามัญ',
      description: 'ผลการเรียนสามัญ'
    }
  },
  {
    path: '/agamaScore',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'AgamaScore',
        component: require('@/views/backend/AgamaScore.vue').default,
        beforeEnter: authGuard
      },
    ],
    meta: {
      title: 'kutipan markah',
      description: 'kutipan markah',
    }
  },
  {
    path: '/agamaScoreDetail/:agr_id_code/:agrq_id_code/:suj_agm_id_code/:stf_id_code',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'AgamaScoreDetail',
        component: require('@/views/backend/AgamaScoreDetail.vue').default,
        beforeEnter: authGuard,
      }
    ],
    meta: {
      title: 'kutipan markah',
      description: 'kutipan markah',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active" // active class for *exact* links.
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters['auth/logedIn']){
      router.push({
        name: 'Login' //ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
      })
    } else {
      next()
    }
  }else if(to.matched.some(record => record.meta.requiresVisitor)) {
    if(store.getters['auth/logedIn']){
      router.push({
        name: 'Dashboard' //ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
      })
    }else{
      next()
    }
  }else{
    next()
  }
})

export default router
