<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <i class="fa fa-home"></i>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">madah</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>    
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <h3 class="text-right font-amiri-header" dir="rtl">مركه</h3>
            </div>
            <div class="d-flex flex-row-reverse">
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                    <select class="form-control" v-model="agr_id" @change="transformResult($event.target.value, false)">
                        <option v-for="(item, index) in classroom" :value="item.agr_id" :key="index">
                            <span class="text-right" dir="rtl">{{ item.agr_grade }}/{{ item.agrroom_name }} [{{ item.agr_semester }}/{{ item.agr_academic_year }}]</span>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-lg btn-block font-amiri" :class="{'btn-success': isShowAll, 'btn-default': !isShowAll}" @click="transformResult('', true)">سموا فڠݢل</button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-lg btn-block font-amiri" :class="{'btn-success': !isShowAll, 'btn-default': isShowAll}" @click="transformResult(agr_id, false)">فڠݢل يڠ دفيليه</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <span v-for="(item, index) in studyResult" :key="index">
                    <ul class="list-group mt-2" v-if="item.show">
                        <li class="list-group-item bg-secondary">
                            <p class="float-right font-amiri-header text-white"> [{{item.semesterInfo.agr_semester}}/{{item.semesterInfo.agr_academic_year}}] {{item.semesterInfo.agr_grade}}/{{item.semesterInfo.agr_room}}  كلس </p>   
                        </li>
                        <li class="list-group-item" v-for="(item, index) in item.subjectResult" :key="index" dir="rtl">
                            <div style="display: flex; justify-content: space-between;">
                                <span class="font-amiri">{{ item.sujcode_agm }} {{ item.sujname_agm_ar }}</span>
                                <span>{{ item.score }}</span>
                            </div>
                            <div class="text-muted font-amiri-muted float-right">
                                <span v-if="item.gender == 1">أستاذ. {{ item.stf_name_ar }} {{ item.stf_lastname_ar }}</span>
                                <span v-else>أستاذة. {{ item.stf_name_ar }} {{ item.stf_lastname_ar }}</span>
                            </div>
                        </li>
                        <li class="list-group-item bg-secondary" dir="rtl">
                            <div style="display: flex; justify-content: space-between;">
                                <span class="font-amiri" style="color: white;">جمله مادة تيدق لولوس</span>
                                <span class="font-amiri" style="color: white" v-if="item.subject_nopass == 0">تيدق ادا</span>
                                <span class="font-amiri" style="color: white" v-else>{{ item.subject_nopass }} مادة</span>
                            </div>
                        </li>
                        <li class="list-group-item bg-secondary" dir="rtl">
                            <div style="display: flex; justify-content: space-between;">
                                <span class="font-amiri" style="color: white;">GPA</span>
                                <span class="font-amiri" style="color: white;">{{ item.gpa }}</span>
                            </div>
                        </li>
                    </ul>
                </span>
                <br>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            // this.retreiveAgamaResult()
            this.retreiveClassroom()
        },
        data(){
            return{
                scores: [],
                studyResult: [],
                classroom: [],
                agr_id: '',
                isShowAll: false,
                selectedRoom:{
                    agr_room: '',
                    agrroom_name: '',
                    agr_semester: '',
                    agr_acamidemic_year: '',
                },
            }
        },
        methods:{
            transformResult(agr_id, isShowAll){
                if(isShowAll == true){
                    let scores = this.scores.map(item => {
                        item.show = true
                        return item
                    })    
                    this.studyResult = scores
                    this.isShowAll = true
                }else{
                    let scores = this.scores.map(item => {
                        if(item.semesterInfo.agr_id == agr_id){
                            item.show = true
                        }else{
                            item.show = false
                        }
                        return item
                    })
                    this.studyResult = scores
                    this.isShowAll = false
                }
            },
            retreiveAgamaResult(){
                this.$Progress.start()
                http.get('scoreAgama/grade')
                    .then(response => {
                        this.scores = response.data.studyResult
                        this.transformResult(this.agr_id, false)
                        this.$Progress.finish()
                    })
                    .catch(err => {
                        if(err.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveClassroom(){
                http.get('agama/classroom')
                    .then(response => {
                        let classroom = response.data.classrooms
                        let selectedRoom = classroom[classroom.length - 1]
                        this.selectedRoom = selectedRoom
                        this.agr_id = selectedRoom.agr_id
                        this.classroom = classroom
                        this.retreiveAgamaResult()
                    })
                    .catch(err => {
                        if(err.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            },
        }
    }
</script>

<style scoped>

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 575px) {
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
        }
    }
    /* Small devices (landscape phones, less than 768px) */
    @media (min-width: 767.98px) { 
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
            
        }
    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) { 
        .desktop-show{
            display: none;
        }
        .phone-show{
            display: block;
        }
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) { 
        .desktop-show{
            display: block;
        }
        .phone-show{
            display: none;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) { 
        .desktop-show{
            display: block;
        }
        .phone-show{
            display: none;
        }
    }
</style>