<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <i class="fa fa-home"></i>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">
                            <router-link to="/agamaAttendance">
                                <a href="#">madah</a>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">laporan kehadiran</li>
                    </ol>
                    <router-link to="/agamaAttendance">
                        <button class="btn btn-primary btn-sm ml-2 float-right"><i class="fa fa-arrow-left"></i> back</button>
                    </router-link>
                </div>
                <div class="col-sm-6">
                    <!-- <ol class="breadcrumb float-sm-right">
                        <router-link to="/agamaAttendance">
                            <button class="btn btn-primary btn-sm ml-2"><i class="fa fa-arrow-left"></i> back</button>
                        </router-link>
                    </ol> -->
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <table class="table table-bordered bg-white mt-3" dir="rtl">
                    <thead>
                        <tr class="bg-secondary" style="font-size: 20px">
                            <td align="center" class="font-amiri-header text-white" colspan="3">
                                <span class="float-right">
                                    مادة : {{subject}}
                                </span>
                               
                                <span class="float-left">
                                    استاذ\ة : {{teacher}}
                                </span>
                               
                            </td>
                        </tr>
                        <tr class="bg-secondary">
                            <td align="center" class="font-amiri-header text-white">تاريخ</td>
                            <td align="center" class="font-amiri-header text-white">حصة</td>
                            <td align="center" class="font-amiri-header text-white">كحاضران</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in report" :key="index">
                            <td align="center">
                                {{item.date_check}}
                            </td>
                            <td align="center">
                                {{item.hisah}}
                            </td>
                            <td align="center" class="font-amiri">
                                <span v-if="item.status == 1"> حاضر </span>
                                <span v-else-if="item.status == 2">  ليوت </span>
                                <span v-else-if="item.status == 3" class="text-danger">  غائب </span>
                                <span v-else-if="item.status == 4" >  چوتي </span>
                                <span v-else> ع  </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
            this.retreiveDetail()
        },
        data(){
            return{
                report: [],
                subject: '',
                teacher: '',
            }
        },
        methods:{
            retreiveDetail(){
                this.$Progress.start()
                http.get(`attendanceAgama/attendanceAgamaDetail/${this.$route.params.suj_agm_id}/${this.$route.params.agr_id}`)
                    .then(response => {
                        this.report = response.data.report
                        this.subject = response.data.subject
                        this.teacher = response.data.teacher
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                        this.$Progress.fail()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>